import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Typography, TextField, Button, Link, Grid, Box, Alert } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import ForumIcon from '@mui/icons-material/Forum'; // Icon for Discord
import { updateDocument } from '../../firebaseConfig';
import WalletConnector from '../../Controls/WalletConnector';
import { usePeraWallet } from '../../Contexts/PeraWalletContext';
import { algoClient } from '../../algoClient';
import algosdk from 'algosdk';
const FEE_ADDRESS = 'C5RLM45BI6G76WUHJSPPQJA6XN2LJ2MJBSHUJA26TVMSJZXAHXLO6VRTYQ';
const COOPCOINASA=796425061;
function Contact() {
    const peraWallet = usePeraWallet();
  const application = useSelector((state) => state.application);
  const [walletAddress, setWalletAddress] = useState('');
  const [nfd, setNFD] = useState('');
  const [comment, setComment] = useState('');
  const [success, setSuccess] = useState(false);


  useEffect(() => {
    setWalletAddress(application.address);
  }, [application.address]);
  useEffect(() => {
    setNFD(application.nfd);
  }, [application.nfd]);
  function stringToUint8Array(str) {
    return new TextEncoder().encode(str);
}
  const handleSubmit = async (event) => {
     event.preventDefault();
     await handlePayCommentFee();
     const timestamp = new Date().getTime(); // This gives you the time in milliseconds since the Unix epoch
     const uniqueId = `${nfd}_${timestamp}`;
     updateDocument('comments',uniqueId,{nfd,comment, walletAddress}).then(()=>{
        setComment(''); // Clear the input after submission
     }).catch(err=>alert(err));
      // Here you would typically handle the submission e.g., sending it to a server
     
  };    
 
  async function generateAssetTransferTxns({
    to,
    assetId,
    initiatorAddr,
    amount,
    note
  }) {
    const suggestedParams = await algoClient.getTransactionParams().do();
    const algoAmountInBaseUnits = parseFloat(amount) * 1e6; // Convert ALGO to base units
    const noteEncoded = new TextEncoder().encode(note);
  const data = {
    from: initiatorAddr,
    to,
    assetIndex: assetId,
    amount:algoAmountInBaseUnits,
    note:noteEncoded,
    suggestedParams
  };
  console.log(data);
    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject(data);
  
    return [{txn, signers: [initiatorAddr]}];
  }
  async function waitForConfirmation(txId) {
    let lastStatus = await algoClient.status().do();
    let lastRound = lastStatus['last-round'];
    while (true) {
        const txInfo = await algoClient.pendingTransactionInformation(txId).do();
        console.log('txInfo',JSON.stringify(txInfo));
        if (txInfo['confirmed-round'] !== null && txInfo['confirmed-round'] > 0) {
            console.log("Transaction confirmed in round", txInfo['confirmed-round']);
            return txInfo;
        }
        lastRound++;
        await algoClient.statusAfterBlock(lastRound).do();
    }
}
  const handlePayCommentFee = async () => {
    // Donation logic here...
    try {
        if ( !peraWallet.peraWallet.isConnected) {
            peraWallet.paraWallet.reconnect();
            alert('Please connect your wallet and enter a valid amount.');
            return false;
        }

    try {
      console.log('Entering generatePaymentInfo');
      var data = {  to: FEE_ADDRESS,
         initiatorAddr: walletAddress,
         assetId:COOPCOINASA,
         amount:1,
         note:comment
       };
        console.log('data:',data);

       const txGroups = await generateAssetTransferTxns(data);
       console.log('txGroups',txGroups);
       const signedTxnGroup = await peraWallet.peraWallet.signTransaction([txGroups]);
       const {txId} = await algoClient.sendRawTransaction(signedTxnGroup).do();
       waitForConfirmation(txId)
           .then(txInfo => {
               console.log("Transaction information:", txInfo);
               alert(`Transaction information:${txInfo['confirmed-round']}`);
               return true;
            })
            .catch(error => console.error("Failed to confirm transaction:", error));
       setTimeout(() => setSuccess(false), 5000); // Stop confetti after 5 seconds

        } catch (error) {
            console.error('Donation failed:', error);
            alert('Donation failed!');
        }
    } catch (error) {
        console.error('Donation failed:', error);
    }
};
  return (
    <Container maxWidth="sm">
    <Typography variant="h4" gutterBottom>
        Contact Us
    </Typography>
    <Grid container spacing={2} justifyContent="center">
        <Grid item>
            <Link href="https://twitter.com/CoopCoinHQ" target="_blank" color="inherit">
                <Button startIcon={<TwitterIcon />} variant="outlined">
                    Twitter
                </Button>
            </Link>
        </Grid>
        <Grid item>
            <Link href="https://discord.gg/j8ryys4a" target="_blank" color="inherit">
                <Button startIcon={<ForumIcon />} variant="outlined">
                    Discord
                </Button>
            </Link>
        </Grid>
        <Grid item xs={12}>
            <WalletConnector/>
        </Grid>
    </Grid>
    <Box component="form"  onSubmit={handleSubmit} sx={{ mt: 4 }}>
      
        <TextField sx={{backgroundColor:'white'}}
            fullWidth
            label="Leave a Comment"
            variant="outlined"
            multiline
            rows={4}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
        />
        <Button type="submit" disabled={!nfd} variant="contained" color="primary" sx={{ mt: 2 }}>
            Submit (Cost: 1 $Coop )
        </Button>
    </Box>
</Container>
  );
}

export default Contact;
