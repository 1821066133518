import React from 'react';
import { Typography } from '@mui/material';

import './AlgoAddict420.css';

const AlgoAddict420 = () => {
  const handleOpenLink = () => {
    window.open("https://app.nf.domains/name/algoaddict420.algo", "_blank", "noopener,noreferrer");
};
  return (
    <div className="algo-addict-container">
      <span 
        className="algo-addict-text"
        onClick={() => window.open("https://twitter.com/algoaddict420")}
      >
        Website made by: @AlgoAddict420
      </span>
      <Typography
            variant="body1"
            component="span"
            className="algo-addict-nfd-text"
            onClick={handleOpenLink}
            sx={{
                cursor: 'pointer',
                color: 'blue', // Add styling as needed
                textDecoration: 'underline'
            }}
        >
            NFD: algoaddict420.algo
        </Typography>
    </div>
  );
};

export default AlgoAddict420;
