// WalletConnector.js
import React, { useState, useEffect } from 'react';
import { FaWallet } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { setAddress, setNFD } from '../../applicationSlice';
import { usePeraWallet } from '../../Contexts/PeraWalletContext';
import './WalletConnector.css';
import { Box, Button, IconButton, Typography } from '@mui/material';
import axios from 'axios';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet'; 
const WalletConnector = () => {
  const dispatch = useDispatch();
  const application = useSelector((state) => state.application);
  const [error, setError] = useState(null);
  const [walletAddress, setWalletAddress] = useState('');
  const [domainInfo, setDomainInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [localNFD, setLocalNFD] = useState('');
  const { peraWallet } = usePeraWallet();
  const isConnectedToPeraWallet = !!walletAddress;

  useEffect(() => {
    peraWallet.reconnectSession().then((accounts) => {
      peraWallet.connector?.on('disconnect', handleDisconnectWalletClick);
      if (accounts.length) {
        dispatch(setAddress(accounts[0]));
      }
    });
  }, [dispatch, peraWallet]);

  useEffect(() => {
    setWalletAddress(application.address);
    if(application.address){
      handleLookup(application.address);

    }else{
      setDomainInfo({name:''})
    }
  }, [application.address]);

  const handleConnectWalletClick = () => {
    peraWallet
      .connect()
      .then((newAccounts) => {
        peraWallet.connector?.on('disconnect', handleDisconnectWalletClick);
        dispatch(setAddress(newAccounts[0]));
        setWalletAddress(newAccounts[0]);
      })
      .catch((error) => {
        if (error?.data?.type !== 'CONNECT_MODAL_CLOSED') {
          console.error(error.data);
        }
      });
  };

  const handleDisconnectWalletClick = () => {
    peraWallet.disconnect();
    dispatch(setAddress(null));
    setWalletAddress('');
  };
  const handleLookup = async (address) => {
    if(!address)return;
    setLoading(true);
    setError(null);
    try {
        const response = await axios.get(`https://us-central1-coop-coin-official.cloudfunctions.net/NFDLookup?address=${address.trim()}`);
        const data =response.data;
        const firstKey = Object.keys(response.data)[0];
        const nfdData = data[firstKey];
        console.log('nfdData',nfdData);

        const secondKey = Object.keys(nfdData)[0];
        const d2 = nfdData[secondKey];
        console.log('d2',d2);
        if(d2!==null && d2.name.length>0){
          dispatch(setNFD(d2.name));
          setLocalNFD(d2.name);
          setDomainInfo(d2);
        }
       
    } catch (err) {
        setError(`Failed to fetch domain information ${err}`);
        setDomainInfo(null);
    } finally {
        setLoading(false);
    }
};
  return (
    <Box >
      <IconButton  color="secondary" onClick={isConnectedToPeraWallet ? handleDisconnectWalletClick : handleConnectWalletClick}>
        <WalletIcon />
        <Typography component={'p'} variant='body'>
        {walletAddress ? 'Disconnect Wallet' : 'Connect Wallet'}
        </Typography>
      </IconButton>
      {loading && <Typography>Loading...</Typography>}
            {error && 
            <Typography color="error">{error}</Typography>}
            {localNFD ? (
                <Box sx={{textAlign:'center'}}>
                    <Typography color={(theme) => theme.palette.grey[500]} variant='body' fontWeight={'bold'} component={'p'}>NFD: {localNFD}</Typography>
                    </Box>
            ): walletAddress &&  <Box sx={{textAlign:'center'}}>
            <Typography variant='body' fontWeight={'bold'} component={'p'}>NFD: {walletAddress.substring(0-8)}</Typography>
            </Box> }

   

    </Box>
  );
};

export default WalletConnector;
