import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { usePeraWallet } from '../../Contexts/PeraWalletContext';
import { WidgetController } from '@tinymanorg/tinyman-swap-widget-sdk';


const SwapToCoop = () => {
    const application = useSelector(state => state.application);
    const [address, setAddress] = useState('');
    const iframeRef = useRef(null);
    const peraWallet = usePeraWallet();

    const params = {
        platformName: "CoopCoin.Online",
        useParentSigner: false,
        assetIds: [
            0,
            796425061
        ],
        network: "mainnet",
        themeVariables: {
            theme: "dark",
            containerButtonBg: "#f3cf00",
            widgetBg: "#b11c98",
            headerButtonBg: "#b11c98",
            headerButtonText: "#ffffff",
            headerTitle: "#ffffff",
            containerButtonText: "#ffffff",
            iframeBg: "#F8F8F8",
            borderRadiusSize: "medium",
            title: "Buy Coop",
            shouldDisplayTinymanLogo: false,
        },
        parentUrlOrigin: window.location.origin,
        accountAddress: address
    };
console.log(params);
    var iframe = WidgetController.generateWidgetIframeUrl(params);

    const onTxnSignRequest = useCallback(async ({ txGroups }) => {
        try {
            console.log(txGroups);
         
           //Sign Transaction
          //  console.log(signedTxns)
         //   WidgetController.sendMessageToWidget({
          //      data: { message: { type: "TXN_SIGN_RESPONSE", signedTxns } },
          //      targetWindow: iframeRef.current?.contentWindow,
          //  });
        } catch (error) {
            console.error(error);
            WidgetController.sendMessageToWidget({
                data: { message: { type: "FAILED_TXN_SIGN", error } },
                targetWindow: iframeRef.current?.contentWindow,
            });
        }
    }, [address]);

    const onTxnSignRequestTimeout = useCallback(() => {
        console.error("txn sign request timed out");
    }, []);

    const onSwapSuccess = useCallback((response) => {
        console.log({ response });
    }, []);

    useEffect(() => {
        if (application.address) {
            setAddress(application.address);
        }
    }, [application.address]);


    useEffect(() => {
        const swapController = new WidgetController({
            onTxnSignRequest,
            onTxnSignRequestTimeout,
            onSwapSuccess,
        });

        swapController.addWidgetEventListeners();

        return () => {
            swapController.removeWidgetEventListeners();
        };
    }, [onSwapSuccess, onTxnSignRequest, onTxnSignRequestTimeout]);

    return (
        <iframe title="tinyman swap widget"
            src={iframe}
            width='415px' height='440px' border='none' sandbox="allow-same-origin allow-scripts allow-popups allow-forms" />
    );
};

export default SwapToCoop;
