// Import necessary libraries
import React, { useState } from 'react';
import algosdk from 'algosdk'
import './NFTMinter.css'; // Adjust the path as needed
import { makeAssetCreateTxnWithSuggestedParamsFromObject } from 'algosdk';
const API_KEY = 'your_nft_storage_api_key';  // Your NFT.Storage API key
const ALGOD_SERVER = 'https://testnet-algoexplorerapi.io';
const ALGOD_PORT = '';
const ALGOD_TOKEN = '';
export default function NFTMinter() {
    const [nftData, setNftData] = useState({
        name: '',
        description: '',
        image: null
    });
    const [response, setResponse] = useState('');
    const [address,setAddress]=useState('');

    // Function to handle input changes
    const handleChange = (event) => {
        const { name, value, files } = event.target;
        if (name === "image") {
            setNftData({ ...nftData, image: files[0] });
        } else {
            setNftData({ ...nftData, [name]: value });
        }
    };

    // Function to handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Upload image to NFT.Storage
     //      const client = new NFTStorage({ token: API_KEY });
     //      const metadata = await client.store({
     //          name: nftData.name,
     //          description: nftData.description,
     //          image: new File([nftData.image], nftData.image.name, { type: nftData.image.type })
     //      });

     //      // Mint NFT using Algorand
     //      const algodClient = new algosdk.Algodv2(ALGOD_TOKEN, ALGOD_SERVER, ALGOD_PORT);
     //      const params = await algodClient.getTransactionParams().do();
     //      const note = new Uint8Array(Buffer.from(JSON.stringify({
     //          title: nftData.name,
     //          description: nftData.description,
     //          image: metadata.url
     //      })));

          // const txn = makeAssetCreateTxnWithSuggestedParamsFromObject({
          //     from: address,
          //     total: 1,
          //     decimals: 0,
          //     assetName: nftData.name,
          //     unitName: "NFT",
          //     assetURL: metadata.url,
          //     assetMetadataHash: null,  // Optionally calculate metadata hash
          //     note,
          //     suggestedParams: params,
          //     manager: address,
          //     reserve: address,
          //     freeze: address,
          //     clawback: address,
          //     defaultFrozen: false
          // });

            // Here you'd usually sign the transaction and send it

           // setResponse(`NFT Created! Metadata URL: ${metadata.url}`);
        } catch (error) {
            console.error('Error minting NFT:', error);
            setResponse('Failed to mint NFT');
        }
    };

    return (
        <div>
            <h1>Mint NFT</h1>
            <form onSubmit={handleSubmit}>
                <input type="text" name="name" value={nftData.name} onChange={handleChange} placeholder="NFT Name" required />
                <textarea name="description" value={nftData.description} onChange={handleChange} placeholder="Description" required />
                <input type="file" name="image" onChange={handleChange} required />
                <button type="submit">Mint NFT</button>
            </form>
            <p>{response}</p>
        </div>
    );
}
