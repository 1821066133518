// PeraWalletContext.js
import React, { createContext, useContext, useState } from 'react';
import { PeraWalletConnect } from '@perawallet/connect';

const PeraWalletContext = createContext();
const peraWallet =new PeraWalletConnect();

export const usePeraWallet = () => useContext(PeraWalletContext);

export const PeraWalletProvider = ({ children }) => {

  return (
    <PeraWalletContext.Provider value={{ peraWallet }}>
      {children}
    </PeraWalletContext.Provider>
  );
};
