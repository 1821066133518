import firebase, { initializeApp } from 'firebase/app';
import 'firebase/firestore';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);

// Get a reference to the Firestore service
const db = getFirestore(app);
const saveEmail = async (email)=>{
    try {
        const data ={
          email: email,
          registrationDate: new Date().toUTCString()  // Using ISO string for the date
        };
        return updateDocument('users',email,data,true);
      } catch (error) {
        console.error("Error adding document: ", error);
       return 'Failed to register email!';
      }
}
const updateDocument=(collection,docId,data,merge)=>{
    var wrappedData =Object.assign(data,{lastModified:new Date().toUTCString()});
    console.log('wrapped data',wrappedData);
     var docRef = doc(db,collection,docId);
     return setDoc(docRef,wrappedData,{merge:merge});
    }
export { db,saveEmail,updateDocument };
