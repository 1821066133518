import React from 'react';
import './About.css';
const About = () => {
  return ( <div className="about-container">
    <section className="about-section">
      <h1 className="about-title">Welcome to the Future of Finance: Algorand Blockchain</h1>
      <p className="about-text">
        In the rapidly evolving world of blockchain technology, Algorand stands out as a leading platform that combines high performance, security, and decentralization. Designed by MIT professor and Turing Award winner Silvio Micali, Algorand aims to solve the blockchain trilemma, delivering a truly scalable and secure blockchain that supports smart contracts, NFTs, and DeFi applications.
      </p>
      <p className="about-text">
        Algorand's unique Pure Proof-of-Stake (PPoS) consensus mechanism ensures rapid transactions with minimal fees, making it an ideal platform for building decentralized applications (dApps) and financial products. Its commitment to sustainability and low carbon footprint further distinguishes Algorand as a blockchain for the future.
      </p>
    </section>
    <section className="about-section">
      <h2 className="about-subtitle">Introducing Coop Coin ($COOP)</h2>
      <p className="about-text">
        Created by visionary entrepreneur Cooper Daniels, Coop Coin ($COOP) is an exciting new token available for trade and use on the Algorand blockchain. Coop Coin is more than just a digital currency; it represents a new way of thinking about community, value, and engagement in the web3 space.
      </p>
      <ul className="about-list">
        <li className="about-list-item">
          <strong>Speed and Efficiency:</strong> Leveraging Algorand's lightning-fast transaction speeds, Coop Coin ensures that every transaction is processed in seconds with negligible fees, providing a seamless user experience.
        </li>
        <li className="about-list-item">
          <strong>Security:</strong> Built on Algorand's robust infrastructure, Coop Coin offers unparalleled security, ensuring that your assets and transactions are always protected.
        </li>
        <li className="about-list-item">
          <strong>Eco-Friendly:</strong> In alignment with Algorand’s commitment to sustainability, Coop Coin operates with a minimal carbon footprint, making it a green choice for conscientious investors and users.
        </li>
        <li className="about-list-item">
          <strong>Community-Focused:</strong> Designed with the community in mind, Coop Coin aims to foster engagement and collaboration among its users, creating a vibrant and active ecosystem.
        </li>
      </ul>
    </section>
    <section className="about-section">
      <h2 className="about-subtitle">Why Choose Coop Coin?</h2>
      <p className="about-text">
        As the digital landscape evolves, Coop Coin represents a forward-thinking approach to digital finance and community building. By choosing Coop Coin, you are not only investing in a token with strong fundamentals but also joining a movement that values innovation, security, and sustainability.
      </p>
      <p className="about-text">
        <strong>Join Us in Shaping the Future:</strong> Whether you are a seasoned web3 enthusiast or new to the world of blockchain, Coop Coin offers a unique opportunity to be part of an exciting journey. Embrace the future of finance with Coop Coin on the Algorand blockchain and experience the benefits of a truly revolutionary digital asset.
      </p>
      <p className="about-text">
        <strong>Trade. Engage. Thrive.</strong> Welcome to Coop Coin – the coin for the community, by the community.
      </p>
    </section>
  </div>
  );
};

export default About;
