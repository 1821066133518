import React from 'react';
import './Banner.css'; // Import CSS for styling
import desktop from '../../Images/banner-desktop.png'
import mobile from '../../Images/banner-mobile.png'

const Banner = () => {
  return (
    <div className="banner">
      <picture>
        {/* For mobile devices */}
        <source media="(max-width: 600px)" srcSet={mobile} />

        {/* For desktop devices */}
        <source media="(min-width: 601px)" srcSet={desktop} />

        {/* Fallback image */}
        <img src={desktop} alt="Banner Image" />
      </picture>
    </div>
  );
};

export default Banner;
