import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    showPrompt:true,
    walletProvider:'',
    address:'',
    nfd:''
}
const applicationSlice = createSlice({
    name:'application',
    initialState:initialState,
    reducers: {
        setShowPrompt (state,action) {
            state.showPrompt=action.payload;
         },
         setWalletProvider(state,action){
            state.walletProvider=action.payload;
          },
          setAddress(state,action){
            state.address = action.payload;
          },
          setNFD(state,action){
            state.nfd = action.payload
          },
          disconnectAndBurn: (state) => ({ ...initialState, state }),
        
    },
 
})



export const {
    setShowPrompt,
    setAddress,
    setWalletProvider,
    disconnectAndBurn,
    setNFD
} = applicationSlice.actions
export default applicationSlice.reducer



