import React, { useState } from 'react';
import { Dialog, IconButton, Typography, DialogContent, Box, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../Images/logo192.png'
import CryptoDonation from '../CryptoDonation';
import WalletConnector from '../WalletConnector';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const Modal = ({ isOpen, closeModal,completionGif }) => {
  const [donationComplete, setDonationComplete] = useState(false);
  // Function to close the modal
  const handleClose = () => {
    closeModal();
    setDonationComplete(false); // Reset on close
  };
  const handleDonationSuccess = () => {
    setDonationComplete(true);
};
  // Render modal content if isOpen is true


return (
    <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
    >
        <DialogContent dividers>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <Grid container columnGap={0}>
              <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <img src={logo} alt="Modal Image" style={{ maxWidth: '100%', height: 'auto' }} />
                </Box>
              </Grid>
              
              <Grid item xs={12}>
              <Box sx={{ textAlign: 'center' }}>
               
            <Typography variant='h6' id="modal-description" >
                Welcome to the Official Coop Coin Website!
            </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
              <WalletConnector/>
              </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center'}}>
            <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
             <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', }}>
               

                <Typography fontWeight={'bold'} color={'primary'}  >Donate to the CRV</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
            {donationComplete ? (
                <Typography>Thank you!!!</Typography>
            ) : (
              
                <CryptoDonation onDonationSuccess={handleDonationSuccess} />
            )}
            </AccordionDetails>
        </Accordion>
            
            </Box>
              </Grid>
              <Grid item sx={12}>
             
              </Grid>
            </Grid>
         
          

           
        </DialogContent>
    </Dialog>
);
};

export default Modal;
