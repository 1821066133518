import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, Container, FormControl, InputLabel, Select, MenuItem, Tooltip, Box } from '@mui/material';
import { usePeraWallet } from '../../Contexts/PeraWalletContext';
import WalletConnector from '../WalletConnector';
import { useDispatch, useSelector } from 'react-redux';
import algosdk from 'algosdk';
import {algoClient} from '../../algoClient';
import Confetti from 'react-confetti';
const CRV_ADDRESS = 'COOPVAULTMTZQXVEFFDU7QPTEYT7OSEX56H37MJFF3OTIR7ZMNMSIGHVOE';
const CryptoDonation = ({ onDonationSuccess  }) => {
    const application = useSelector(state=>state.application);
    const peraWallet = usePeraWallet();
    const dispatch = useDispatch();
    const [amount, setAmount] = useState('');
    const [note, setNote] = useState('');
    const [address, setAddress] = useState('');
    const [selectedAsset, setSelectedAsset] = useState('');// Default to Algorand, use asset ID for ASAs
    const [donationSuccess, setDonationSuccess] = useState(false)
    const handleDonate = async () => {
        // Donation logic here...
        try {
            console.log('PeraWalletConnected:',peraWallet.peraWallet);
            if (!amount || !peraWallet.peraWallet.isConnected) {
                peraWallet.paraWallet.reconnect();
                alert('Please connect your wallet and enter a valid amount.');
                return;
            }
    if(selectedAsset===0){
        try {
            const txGroups = await generatePaymentTxns({
                to: CRV_ADDRESS,
              initiatorAddr: address
            });
            console.log('txGroups',txGroups);
            const signedTxnGroup = await peraWallet.peraWallet.signTransaction([txGroups]);
            const {txId} = await algoClient.sendRawTransaction(signedTxnGroup).do();
            setDonationSuccess(true);
            
        setTimeout(() => setDonationSuccess(false), 5000); // Stop confetti after 5 seconds
        return txId;
        } catch (error) {
            console.error('Donation failed:', error);
            alert('Donation failed!');
        }
    }else{
        handlePayCommentFee().then((val)=>{
            setDonationSuccess(true);

        }).catch(err=>console.log(err));
    }
           
        } catch (error) {
            console.error('Donation failed:', error);
        }
    };

    async function generateAssetTransferTxns({
        to,
        assetId,
        initiatorAddr,
        amount,
        note
      }) {
        const suggestedParams = await algoClient.getTransactionParams().do();
        const algoAmountInBaseUnits = parseInt(amount) * 1e6; // Convert ALGO to base units
        const noteEncoded = new TextEncoder().encode(note);
      const data = {
        from: initiatorAddr,
        to,
        assetIndex: parseInt(assetId),
        amount:algoAmountInBaseUnits,
        note:noteEncoded,
        suggestedParams
      };
      console.log(data);
        const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject(data);
      
        return [{txn, signers: [initiatorAddr]}];
      }
      async function waitForConfirmation(txId) {
        let lastStatus = await algoClient.status().do();
        let lastRound = lastStatus['last-round'];
        while (true) {
            const txInfo = await algoClient.pendingTransactionInformation(txId).do();
            console.log('txInfo',JSON.stringify(txInfo));
            if (txInfo['confirmed-round'] !== null && txInfo['confirmed-round'] > 0) {
                console.log("Transaction confirmed in round", txInfo['confirmed-round']);
                return txInfo;
            }
            lastRound++;
            await algoClient.statusAfterBlock(lastRound).do();
        }
    }
      const handlePayCommentFee = async () => {
        // Donation logic here...
        try {
            if ( !peraWallet.peraWallet.isConnected) {
                peraWallet.paraWallet.reconnect();
                alert('Please connect your wallet and enter a valid amount.');
                return false;
            }
    
        try {
          console.log('Entering generatePaymentInfo');
          var data = {  to: CRV_ADDRESS,
             initiatorAddr: address,
             assetId:selectedAsset,
             amount:amount,
             note:note
           };
            console.log('data:',data);
    
           const txGroups = await generateAssetTransferTxns(data);
           console.log('txGroups',txGroups);
           const signedTxnGroup = await peraWallet.peraWallet.signTransaction([txGroups]);
           const {txId} = await algoClient.sendRawTransaction(signedTxnGroup).do();
           waitForConfirmation(txId)
               .then(txInfo => {
                   console.log("Transaction information:", txInfo);
                   alert(`Transaction information:${txInfo['confirmed-round']}`);
           setTimeout(() => {
            
            donationSuccess(false);}, 5000); // Stop confetti after 5 seconds

                   return true;
                })
                .catch(error => {
                    alert(error);
                    console.error("Failed to confirm transaction:", error);}
                );
    
            } catch (error) {
                console.error('Donation failed:', error);
                alert('Donation failed!');
            }
        } catch (error) {
            console.error('Donation failed:', error);
        }
    };
    useEffect(()=>{
        setAddress(application.address);
    },[application.address])
    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    };
    const handleNoteChange = (event) => {
        setNote(event.target.value);
    };
    function stringToUint8Array(str) {
        return new TextEncoder().encode(str);
    }
 
    const handleAssetChange = (event) => {
        setSelectedAsset(event.target.value);
    
    };
    async function generatePaymentTxns({to, initiatorAddr}) {
        const suggestedParams = await algoClient.getTransactionParams().do();
        const algoAmountInBaseUnits = parseFloat(amount) * 1e6; // Convert ALGO to base units
      console.log('algoAmountInBaseUnits',algoAmountInBaseUnits);
      const noteUint8Array = stringToUint8Array(note);
        const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
          from: initiatorAddr,
          to,
          amount: algoAmountInBaseUnits,
          note:noteUint8Array,
          suggestedParams
        });
      
        return [{txn, signers: [initiatorAddr]}];
      }
   
    const handleOpenLink = () => {
        window.open("https://app.nf.domains/name/crv.coop.algo", "_blank", "noopener,noreferrer");
    };
    return (
        <Container maxWidth="sm" >
           
         
          
            <TextField
                fullWidth
                label="Amount"
                type="number"
                value={amount}
                onChange={handleAmountChange}
                variant="outlined"
                margin="normal"
           
            />
                   
            <FormControl fullWidth margin="normal">
                <InputLabel id="asset-select-label">Asset</InputLabel>
                <Select
                    labelId="asset-select-label"
                    id="asset-select"
                    value={selectedAsset}
                    label="Asset"
                    onChange={handleAssetChange}
                >
                    <MenuItem value="0">Algorand ($ALGO)</MenuItem>
7                    <MenuItem value="796425061">Coop Coin ($)Coop</MenuItem>
                    <MenuItem value="1682662165">Algo to $200 ($A200)</MenuItem>
                    <MenuItem value="1392353330">Ponchik ($PURR)</MenuItem>
                    {/* Add more assets as needed */}
                </Select>
            </FormControl>
            <TextField
                fullWidth
                label="Note"
                type="text"
                multiline
                rows={3}
                value={note}
                onChange={handleNoteChange}
                variant="outlined"
                margin="normal"
           
            />
            <Button variant="contained" color="secondary" onClick={handleDonate}>
                Donate
            </Button>
            {donationSuccess && <Confetti />}
        </Container>
    );
};

export default CryptoDonation;
