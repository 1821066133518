import React from 'react';
import './RoadmapStep.css';
import { Link } from '@mui/material';

const RoadmapStep = ({ title, description, date, accomplished,link, label,imgUrl  }) => {
  console.log('link',link);
  console.log('label',label);


  return (
    <div className={`roadmap-step ${accomplished ? 'accomplished' : 'planned'}`}>
      <h3>{title}</h3>
      <p>{description}</p>
      <p className="date">{date}</p>
      {link && !imgUrl?<Link 
  href={link} 
  target="_blank" 
  rel="noopener noreferrer"
  sx={{ color: 'secondary.main', typography: 'body1', ':hover': { textDecoration: 'none' } }}
>
 {label}
</Link>:''}
{imgUrl?<Link href={link} 
  target="_blank" 
  rel="noopener noreferrer"><img height={60} src={imgUrl}/></Link> :''}
    </div>
  );
};

export default RoadmapStep;
