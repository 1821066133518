import React from 'react';
import {  Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import './App.css';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Banner from './Controls/Banner';
import Nav from './Controls/Nav';
import AlgoAddict420 from './Controls/AlgoAddict420';
import Roadmap from './Pages/Roadmap';
import SwapComponent from './Controls/SwapComponent';
import { PeraWalletProvider } from './Contexts/PeraWalletContext';
import SwapToCoop from './Pages/SwapToCoop';
import NFTMinter from './Pages/NFTMinter';

function App() {

  return (
    <PeraWalletProvider>
      <div className="App">
     
          <Banner />
        <Nav/>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/roadmap" element={<Roadmap />} />
          <Route path="/swap" element={<SwapToCoop />} />
          <Route path="/nft-minter" element={<NFTMinter />} />


        </Routes>

        <footer className="homepage-footer">
      <AlgoAddict420/>

      <p>&copy; 2024 Coop Coin. All rights reserved.</p>
    </footer>
   
      </div>
      </PeraWalletProvider>
  );
}

export default App;