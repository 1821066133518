import React, { useState, useEffect } from 'react';
import algosdk from 'algosdk'
import { useSelector } from 'react-redux';
import {
  convertFromBaseUnits,
  ALGO_ASSET,
  sendAndWaitRawTransaction,
  getSwapRoute,
  getBestQuote,
  generateSwapRouterTxns,
  poolUtils,
  Swap,SwapType
} from '@tinymanorg/tinyman-js-sdk';
import { usePeraWallet } from '../../Contexts/PeraWalletContext';
import './SwapComponent.css';

const port = "";
const token = ""; 
const indexer = `https://mainnet-idx.algonode.cloud`;
const algoNode = `https://mainnet-api.algonode.cloud`;
const algodClient = new algosdk.Algodv2(token, algoNode, port);
const indexerClient = new algosdk.Indexer(token, indexer, port);

const SwapComponent = () => {
  const [account, setAccount] = useState(null);
  const [algoAmount, setAlgoAmount] = useState('');
  const [estimatedCoop, setEstimatedCoop] = useState('');
  const [error, setError] = useState(null);
  const { peraWallet } = usePeraWallet();

  useEffect(() => {
    if (peraWallet) {
      peraWallet.connect().then((accounts) => {
        const address = accounts[0];
        console.log(`Connecting to:${address}`);
        setAccount(address);
      }).catch((err) => {
        console.error('Pera Wallet initialization failed:', err);
        setError('Failed to initialize Pera Wallet');
      });
    }
  }, [peraWallet]);

  const handleAlgoAmountChange = async (e) => {
    const amount = e.target.value;
    setAlgoAmount(amount);

    if (amount && peraWallet) {
      try {
        const algoAssetId = Number(0); // ALGO's asset ID, explicitly converted to a number
        const coopAssetId = Number(796425061);
        const algoAmountInBaseUnits = parseFloat(amount) * 1e6; // Convert ALGO to base units
        const pool = await poolUtils.v2.getPoolInfo({network:"mainnet",client:algodClient,asset1ID:algoAssetId,asset2ID:coopAssetId});
        
        const fixedInputSwapQuote = await Swap.v2.getQuote(
            SwapType.FixedInput,
            pool,
            {id: pool.asset1ID, amount: 1_000_000},
            {assetIn: 6, assetOut: 6}
          );
       
console.log(fixedInputSwapQuote)
      

        setEstimatedCoop(fixedInputSwapQuote.data);
        setError(null);
      } catch (err) {
        console.error('Failed to fetch swap quote:', err);
        setError('Failed to fetch swap quote');
        setEstimatedCoop('');
      }
    } else {
      setEstimatedCoop('');
    }
};

  const handleSwap = async () => {
    if (peraWallet) {
      try {
        const coopAssetId = 796425061;
        const algoAmountInBaseUnits = parseFloat(algoAmount) * 1e6; // Convert ALGO to base units

        const swapRoute = await getSwapRoute({
          network: 'mainnet',
          assetIn: { ...ALGO_ASSET, amount: algoAmountInBaseUnits },
          assetOut: { assetId: coopAssetId },
        });

        const swapQuote = await getBestQuote(swapRoute);
        const txns = await generateSwapRouterTxns(swapQuote);
        const signedTransactions = await peraWallet.signTransaction(txns);
        const result = await sendAndWaitRawTransaction(signedTransactions);

        setError(null);
        alert('Swap successful!');
      } catch (err) {
        console.error('Swap failed:', err);
        setError('Swap failed');
      }
    }
  };

  return (
    <div className="swap-component">
      <h2>Swap ALGO to COOP</h2>
      {account && <p>Connected Account: {account}</p>}
      <div className="swap-inputs">
        <input
          type="number"
          value={algoAmount}
          onChange={handleAlgoAmountChange}
          placeholder="Enter ALGO amount"
        />
        <input
          type="text"
          value={estimatedCoop}
          readOnly
          placeholder="Estimated COOP output"
        />
      </div>
      <button onClick={handleSwap}>Swap</button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default SwapComponent;
