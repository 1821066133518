import { configureStore,combineReducers } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import applicationSlice from './applicationSlice'


const reducer = combineReducers(
  {
    application:applicationSlice
  })

const store = configureStore({
                              reducer: reducer,
                              middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger).concat(),
                              devTools: process.env.NODE_ENV !== 'production',
                            })

export default store