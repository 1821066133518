import React, { useEffect, useState } from 'react';
import './HomePage.css';
import Modal from '../../Controls/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setShowPrompt } from '../../applicationSlice';
import { Link } from 'react-router-dom';
import SwapComponent from '../../Controls/SwapComponent';
import { saveEmail } from '../../firebaseConfig';
import { Button, Snackbar, Alert } from '@mui/material';
function HomePage() {
  const dispatch = useDispatch();
  const application = useSelector(state=>state.application);
  const [modalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
     saveEmail(email).then((res)=>{
      setOpenSnackbar(true); // Open the Snackbar on success
      setEmail('');
     }
    ).catch((err)=>{
      if(err.toString().includes('FirebaseError: [code=permission-denied]: Missing or insufficient permissions.')){
        setError('You are already registered!');
        setOpenErrorSnackbar(true);
        setEmail('');
      }
     })
    }
  };
useEffect(()=>{
  setModalOpen(application.showPrompt);
},[application.showPrompt])


  const closeModal = () => {
    dispatch(setShowPrompt(false));

  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpenSnackbar(false);
};
const handleCloseErrorSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
      return;
  }
  setOpenErrorSnackbar(false);
};
  return (    
    <div className="home-container">
        <Modal isOpen={modalOpen} closeModal={closeModal} completionGif={''}/>
    <section className="hero-section">
      <div className="hero-content">
        <h1>Welcome to Coop Coin</h1>
        <p>The future of decentralized finance.</p>
        <Link to="/roadmap" className="cta-button">View Roadmap</Link>
      </div>
    </section>
    <section className="features-section">
      <h2>Features</h2>
      <div className="features">
        <div className="feature">
          <i className="icon fa fa-lock"></i>
          <h3>Security</h3>
          <p>Top-notch security for your assets.</p>
        </div>
        <div className="feature">
          <i className="icon fa fa-bolt"></i>
          <h3>Speed</h3>
          <p>Fast transactions with low fees.</p>
        </div>
        <div className="feature">
          <i className="icon fa fa-users"></i>
          <h3>Community</h3>
          <p>Strong and supportive community.</p>
        </div>
      </div>
    </section>
    <section className="roadmap-preview">
      <h2>Roadmap Preview</h2>
      <p>Discover our journey and future plans.</p>
      <Link to="/roadmap" className="link-button">Learn More</Link>
    </section>
    <section className="community-section">
      <h2>Community</h2>
      <p>Join our growing community and be part of the future.</p>
      <div className="testimonials">
        <blockquote>
          "Coop Coin has revolutionized how I manage my assets."
          <cite>- Satisfied User</cite>
        </blockquote>
        <blockquote>
          "Amazing community and support!"
          <cite>- Happy Investor</cite>
        </blockquote>
      </div>
    </section>
    <section className="newsletter-signup">
      <h2>Stay Updated</h2>
      <p>Sign up for our newsletter to get the latest updates.</p>
      <div  className="signup-form">
        <input type="email" onChange={handleInputChange} value={email} placeholder="Enter your email" />
        <button type="button" onClick={handleSubmit}>Sign Up</button>
      </div>
    </section>
    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Thank you! Your email has been successfully registered.
                </Alert>
            </Snackbar>
            <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={handleCloseErrorSnackbar}>
                <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
  </div>
  );
}

export default HomePage;
