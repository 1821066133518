import React from 'react';

import './Roadmap.css';
import RoadmapStep from '../../Controls/RoadmapStep';
import probit from '../../Images/probit_transparent.png'
import messina from '../../Images/messina.png'


const Roadmap = () => {
  const steps = [
    { title: '1 COOP Airdrop', description: 'Everyone who was opted-into COOP got 1 COOP airdrop.', date: 'May 2023', accomplished: true,link:'',label:'' ,imgUrl:''},
    { title: 'OG COOP Airdrop', description: 'Everyone who was opted-into COOP got 59658 COOP.', date: 'May 2023', accomplished: true,link:'',label:'',imgUrl:'' },
    { title: 'Creation of CRV', description: 'CRV (Coomunity Rewards Vault) was established. (Voting power is based on donations).', date: 'June 2023', accomplished: true,link:'',label:'',imgUrl:'' },
    { title: 'GORA LP Deal', description: 'CRV & GoraFi made $COOP/$GORA LP deal to add permanent liquidity into Tinyman liquidity pool.', date: 'December 2023', accomplished: true,link:'',label:'',imgUrl:'' },
    { title: 'COOP NFT Drop', description: '60+ Algorand Artists Came together to create the Official COOP CRV collection! ', date: 'May 2024', accomplished: true, link:'',label:'' },
    { title: 'MessinaOne Token Wars!', description: 'COOP will be bridged onto Avalanche with Algorand Foundation & MessinaOne backed incentives! (Liquidity pools on TraderJoe)***LIVE NOW***', date: 'July 2024 😎 🍻 ', accomplished: true, link:'https://messina.one/bridge',label:'Messina.One Bridge',imgUrl:`${messina}` },
    { title: 'Probit CEX listing', description: 'Coop CEX listing ***is live*** on PROBIT!', date: 'August 2024', accomplished: true, link:'https://www.probit.com/app/exchange/COOP-USDT',label:'PROBIT Listing' ,imgUrl:`${probit}`},
    { title: 'MessinaOne Token Wars!', description: 'A battle for token supremacy!', date: 'December 2024', accomplished: false, link:'https://messina.one/bridge',label:'Messina.One Bridge',imgUrl:`${messina}`},
    { title: 'CRV MessinaOne mAlgo Collab', description: 'Details Soon!', date: 'January 2025', accomplished: false,link:'https://messina.one/bridge',label:'Messina.One Bridge',imgUrl:`${messina}`},


  ];

  return (
    <div className="roadmap">
      <h2>Coop Coin Roadmap</h2>
      {steps.map((step, index) => (
        <RoadmapStep
          key={index}
          title={step.title}
          description={step.description}
          date={step.date}
          accomplished={step.accomplished}
          link={step.link}
          label={step.label}
          imgUrl={step.imgUrl}
        />
      ))}
    </div>
  );
};

export default Roadmap;
